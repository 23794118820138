export const currencies = {
  aud: {
    audcad: '',
    audchf: '',
    audjpy: '',
    audnzd: '',
    audusd: '',
    euraud: '',
    gbpaud: '',
  },
  cad: {
    audcad: '',
    cadchf: '',
    cadjpy: '',
    eurcad: '',
    gbpcad: '',
    nzdcad: '',
    usdcad: '',
  },
  chf: {
    audchf: '',
    cadchf: '',
    chfjpy: '',
    eurchf: '',
    gbpchf: '',
    nzdchf: '',
    usdchf: '',
  },
  eur: {
    euraud: '',
    eurcad: '',
    eurchf: '',
    eurgbp: '',
    eurjpy: '',
    eurnzd: '',
    eurusd: '',
  },
  gbp: {
    eurgbp: '',
    gbpaud: '',
    gbpcad: '',
    gbpchf: '',
    gbpjpy: '',
    gbpnzd: '',
    gbpusd: '',
  },
  nzd: {
    audnzd: '',
    eurnzd: '',
    gbpnzd: '',
    nzdcad: '',
    nzdchf: '',
    nzdjpy: '',
    nzdusd: '',
  },
  jpy: {
    audjpy: '',
    cadjpy: '',
    chfjpy: '',
    eurjpy: '',
    gbpjpy: '',
    nzdjpy: '',
    usdjpy: '',
  },
  usd: {
    audusd: '',
    eurusd: '',
    gbpusd: '',
    nzdusd: '',
    usdcad: '',
    usdchf: '',
    usdjpy: '',
  }
}